var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login" },
    [
      _vm._m(0),
      _c("div", { staticClass: "right" }, [
        _c(
          "div",
          { staticClass: "loginInfo" },
          [
            _vm._m(1),
            _c(
              "el-tabs",
              {
                on: { "tab-click": _vm.handleClick },
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              [
                _c("el-tab-pane", {
                  attrs: { label: "By email", name: "email" },
                }),
                _c("el-tab-pane", { attrs: { label: "By sms", name: "sms" } }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "loginForm" },
              [
                _c(
                  "el-form",
                  {
                    ref: "formRef",
                    staticClass: "demo-form-inline",
                    attrs: {
                      inline: true,
                      model: _vm.registerForm,
                      rules: _vm.rules,
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "username" } },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: { placeholder: "Enter your username" },
                                model: {
                                  value: _vm.registerForm.username,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.registerForm, "username", $$v)
                                  },
                                  expression: "registerForm.username",
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "20px",
                                    "margin-top": "6px",
                                  },
                                  attrs: {
                                    slot: "prefix",
                                    src: require("../../assets/image/username.png"),
                                    alt: "",
                                  },
                                  slot: "prefix",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.activeName == "email"
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "email" } },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      placeholder: "Enter your email address",
                                    },
                                    model: {
                                      value: _vm.registerForm.email,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.registerForm, "email", $$v)
                                      },
                                      expression: "registerForm.email",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "20px",
                                        "margin-top": "6px",
                                      },
                                      attrs: {
                                        slot: "prefix",
                                        src: require("../../assets/image/email.png"),
                                        alt: "",
                                      },
                                      slot: "prefix",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.activeName == "sms"
                      ? _c(
                          "div",
                          { staticClass: "phoneNum" },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "150px" },
                                model: {
                                  value: _vm.registerForm.countrycode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.registerForm,
                                      "countrycode",
                                      $$v
                                    )
                                  },
                                  expression: "registerForm.countrycode",
                                },
                              },
                              _vm._l(
                                _vm.CountryCodeList,
                                function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: `+${item.code}`,
                                      value: item.code,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-right": "0px" },
                                attrs: { prop: "phone" },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "Enter your email address",
                                  },
                                  model: {
                                    value: _vm.registerForm.phone,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.registerForm, "phone", $$v)
                                    },
                                    expression: "registerForm.phone",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "codeMessage" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "code" } },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: { placeholder: "Input message code" },
                                model: {
                                  value: _vm.registerForm.code,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.registerForm, "code", $$v)
                                  },
                                  expression: "registerForm.code",
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "20px",
                                    "margin-top": "6px",
                                  },
                                  attrs: {
                                    slot: "prefix",
                                    src: require("../../assets/image/safety.png"),
                                    alt: "",
                                  },
                                  slot: "prefix",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "sendCodeBtn",
                            attrs: { disabled: _vm.btnText != "Send code" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.sendcode.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.btnText))]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "password" } },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: {
                                  placeholder: "Enter your password",
                                  "show-password": "",
                                },
                                model: {
                                  value: _vm.registerForm.password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.registerForm, "password", $$v)
                                  },
                                  expression: "registerForm.password",
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "20px",
                                    "margin-top": "6px",
                                  },
                                  attrs: {
                                    slot: "prefix",
                                    src: require("../../assets/image/password.png"),
                                    alt: "",
                                  },
                                  slot: "prefix",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "confirmPassword" } },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: {
                                  placeholder: "Confirm your password",
                                  "show-password": "",
                                },
                                model: {
                                  value: _vm.registerForm.confirmPassword,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.registerForm,
                                      "confirmPassword",
                                      $$v
                                    )
                                  },
                                  expression: "registerForm.confirmPassword",
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "20px",
                                    "margin-top": "6px",
                                  },
                                  attrs: {
                                    slot: "prefix",
                                    src: require("../../assets/image/password.png"),
                                    alt: "",
                                  },
                                  slot: "prefix",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "whatsapp" } },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: {
                                  placeholder: "Enter your whatsapp number",
                                },
                                model: {
                                  value: _vm.registerForm.whatsapp,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.registerForm, "whatsapp", $$v)
                                  },
                                  expression: "registerForm.whatsapp",
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "20px",
                                    "margin-top": "6px",
                                  },
                                  attrs: {
                                    slot: "prefix",
                                    src: require("../../assets/image/whatsapp.png"),
                                    alt: "",
                                  },
                                  slot: "prefix",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "otherBtn" },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 1 },
                            model: {
                              value: _vm.remeberRadio,
                              callback: function ($$v) {
                                _vm.remeberRadio = $$v
                              },
                              expression: "remeberRadio",
                            },
                          },
                          [
                            _vm._v(
                              "l agrce to the Terms of Service and Privecy Policy"
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { staticStyle: { "margin-top": "20px" } },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "loginBtn",
                            attrs: { type: "primary" },
                            on: { click: _vm.onSubmit },
                          },
                          [_vm._v("Create account")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("img", {
          staticClass: "closeBtn",
          attrs: { src: require("@/assets/common/close.svg"), alt: "" },
          on: { click: _vm.closeDialog },
        }),
      ]),
      _c("Verify", {
        ref: "verify",
        attrs: {
          mode: "pop",
          captchaType: "blockPuzzle",
          imgSize: { width: "276px", height: "130px" },
        },
        on: { success: _vm.success },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left" }, [
      _c("div", { staticClass: "leftinfo" }, [
        _c("img", {
          attrs: { src: require("@/assets/common/ngnlogo.svg"), alt: "" },
        }),
        _c("div", { staticClass: "infoTitle" }, [_vm._v("Ngncard")]),
        _c("div", { staticClass: "infoAbstract" }, [
          _vm._v(
            " A world-class gift card trading platform that processes over $1 million in cards per day "
          ),
        ]),
        _c("div", { staticClass: "infoLine" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "infoTopTitle" }, [
      _c("div", [_vm._v(" Sign Up ")]),
      _c("div"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
/**
 * 应用常量
 */
const TEST_RESOURCE = {
  API_BASE: 'http://172.19.75.49:9534',
}

const PROD_RESOURCE = {
  API_BASE: 'http://172.19.75.49:9534',
}

const DEBUG_ARRAY = ['development'] // 测试环境
const isDebug = DEBUG_ARRAY.includes(process.env.NODE_ENV)

let current = isDebug ? TEST_RESOURCE : PROD_RESOURCE
current = Object.assign(current)

export default current

import { formatDate } from "@/utils/utils";
const state = {
  defaultSearchForm: {
    // 默认检索条件用于初始化使用
    pageNum: 1,
    pageSize: 10,
    requestVO: {
      filterField: {
        title: "",
        publishDateEnd: "",
        publishDateStart: "",
        publishOrgId: [],
        publishChannel: [],
        daterange: ["", ""],
      },
      key: "",
      sortReq: {
        field: "PUBLISH_DATE", // 字段,可用值:EFFECTIVE_DATE,PUBLISH_DATE,PUBLISH_ORG,SCORE,示例值(PUBLISH_ORG)
        order: "DESC",
      },
      isPrecise: true,
      orderTime: "DESC",
      tabId: 0,
    },
  },
  searchForm: {
    // 检索条件
    pageNum: 1,
    pageSize: 10,
    requestVO: {
      filterField: {
        title: "",
        publishDateEnd: "",
        publishDateStart: "",
        daterange: ["", ""],
        publishOrgId: [],
        publishChannel: [],
      },
      key: "",
      sortReq: {
        field: "PUBLISH_DATE", // 字段,可用值:EFFECTIVE_DATE,PUBLISH_DATE,PUBLISH_ORG,SCORE,示例值(PUBLISH_ORG)
        order: "DESC",
      },
      isPrecise: true,
      orderTime: "DESC",
      tabId: 0,
    },
  },
};
const mutations = {
  SET_SEARCH_FORM(state, obj) {
    state.searchForm = obj;
  },
  SET_FROM_DATE: (state, arr) => {
    if (arr) {
      state.searchForm.requestVO.filterField.publishDateStart =
        arr.daterange[0];
      state.searchForm.requestVO.filterField.publishDateEnd = arr.daterange[1];
    } else {
      state.searchForm.requestVO.filterField.publishDateStart = null;
      state.searchForm.requestVO.filterField.publishDateEnd = null;
    }
  },
  RESET_FROM: (state) => {
    state.searchForm = JSON.parse(JSON.stringify(state.defaultSearchForm));
  },
  NORMAL_SEARCH: (state) => {
    state.searchForm.requestVO.filterField = JSON.parse(
      JSON.stringify(state.defaultSearchForm.requestVO.filterField)
    );
  },
};

const actions = {
  resetSearchForm({ commit }) {
    // 重置
    commit("RESET_FROM");
  },
  changeFormDate({ commit }, daterange) {
    // 修改时间
    commit("SET_FROM_DATE", daterange);
  },
  normalSearchForm({ commit }) {
    // 普通搜索
    commit("NORMAL_SEARCH");
  },
  setSearchForm({ commit }, obj) {
    commit("SET_SEARCH_FORM", obj);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

<template>
  <div style="width:100%;background: #1F4E98;">
    <div class="header">
      <div class="leftLogo">
        <img src="@/assets/common/ngnlogo.png" alt="">
        <div>NGNCARD</div>
      </div>
      <div class="menu">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="Home" name="home"></el-tab-pane>
          <el-tab-pane label="Order" name="order"></el-tab-pane>
          <el-tab-pane label="Sell" name="sellCard"></el-tab-pane>
          <el-tab-pane label="Reward" name="invitation"></el-tab-pane>
          <el-tab-pane label="Wallet" name="wallet"></el-tab-pane>
        </el-tabs>
      </div>

      <div class="userinfo">
        <div class="unlogin" v-if="!userInfo">
          <span style="cursor: pointer;color:#fff;border:1px solid #fff" @click="showLoginDialog('login')" >Sign in</span>
          <span style="cursor: pointer;" @click="showLoginDialog('create')" class="singnup">Sign Up</span>
        </div>
        <div class="logined" v-else>
          <img class="userImg" :src="userInfo.avatar" @click="goTopersonInfo" alt="">
          <el-dropdown trigger="click" @command="handleCommand">
            <span style="cursor: pointer;color:#fff">
              {{userInfo.nickname}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <div class="topInfo">
                  <img class="leftimg" :src="userInfo.avatar" alt="">
                  <div class="ringht">
                    <div class="nickName">{{userInfo.nickname}} <img class="vipinfo" :src="require(`@/assets/common/vip${userInfo.vip}Id.svg`)" alt=""></div>
                    <div>{{userInfo.email}}</div>
                  </div>
                </div>
              </el-dropdown-item>
              <el-dropdown-item divided command="personinfo">
                <div class="mycenterItem" @cilck="goRouter('personinfo')">
                  <img src="@/assets/common/setting.svg" alt="">
                  <div class="ItemLaber">Account settings</div>
                </div>
              </el-dropdown-item>
              <el-dropdown-item command="safety">
                <div class="mycenterItem" @cilck="goRouter('safety')">
                  <img src="@/assets/common/security.svg" alt="">
                  <div class="ItemLaber">Security</div>
                </div>
              </el-dropdown-item>
              <el-dropdown-item command="certification">
                <div class="mycenterItem" @cilck="goRouter('certification')">
                  <img src="@/assets/common/certification.svg" alt="">
                  <div class="ItemLaber">Certification</div>
                </div>
              </el-dropdown-item>
              <el-dropdown-item command="vipinfo">
                <div class="mycenterItem" @cilck="goRouter('vipinfo')">
                  <img src="@/assets/common/vipimg.svg" alt="">
                  <div class="ItemLaber">Vip level</div>
                </div>
              </el-dropdown-item>
              <el-dropdown-item command="helpcenter">
                <div class="mycenterItem" @cilck="goRouter('helpcenter')">
                  <img src="@/assets/common/help.svg" alt="">
                  <div class="ItemLaber">Help center</div>
                </div>
              </el-dropdown-item>
              <el-dropdown-item command="contentus">
                <div class="mycenterItem" @cilck="goRouter('contentus')">
                  <img src="@/assets/common/contact.svg" alt="">
                  <div class="ItemLaber">Contact us</div>
                </div>
              </el-dropdown-item>
              <el-dropdown-item divided command="loginout">
                <div class="mycenterItem" @cilck="goRouter()">
                  <img src="@/assets/common/loginout.svg" alt="">
                  <div class="ItemLaber">Sign out</div>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <img class="bellImg" src="../assets/common/bell.svg" alt="">
        </div>
      </div>
          <!-- 登录 -->
      <el-dialog 
        :visible.sync="showLoginModal" 
        v-if="showLoginModal"
        :show-close="false" 
        :modal="false"
        :destroy-on-close="true" 
        :close-on-click-modal="false"
        width="1060px"
        >
        <Login @loginSuccess="loginSuccess" @routerPush="routerPush" @closeDialog="closeDialog" v-if="showRouter == 'login'"></Login>
        <Register v-if="showRouter == 'create'" @closeDialog="closeDialog" @routerPush="routerPush"></Register>
        <ForgetPsd v-if="showRouter == 'forget'" @closeDialog="closeDialog" @routerPush="routerPush"></ForgetPsd>
      </el-dialog>

    </div>
        
  </div>
   </template>
   
   <script>
   import router from "@/router";
   import { mapState } from 'vuex';
   import Login from '@/components/login/login.vue';
    import Register from '@/components/login/register.vue';
    import ForgetPsd from '@/components/login/forgetPsd.vue';
    import { getToken,getUserId,removeToken,removeUserId} from "@/utils/auth";
    import {customerInfo,usrLogout} from "../../src/api/giftcard";
     export default {
         name: 'header',
         props: {
        
         },
         computed: {
            ...mapState(['showLoginModal'])
          },
          components:{
            Login,
            Register,
            ForgetPsd
          },
         data() {
             return {
              activeName: 'home',
              showRouter:'login',
              userinfo:{},
              menuList:[
                {
                  name:'HOME',
                  path:'home'
                },
                {
                  name:'ORDER',
                  path:'order'
                },
                {
                  name:'REWARD',
                  path:'invitation'
                },
                {
                  name:'WALLET',
                  path:'wallet'
                },
              ]
             }
         },
         created(){
          if(localStorage.getItem('userinfo')){
            console.log('xhkalsskjkkh')
            this.userInfo = JSON.parse(localStorage.getItem('userinfo'))
          }
         },
         beforeCreate() {
            // 全局前置守卫
            router.beforeEach(async (to, from, next) => {
              console.log(this.$store.state.isLoggedIn,"searchForm")
              let isLoggedIn = true
              // if(!getToken()){
              //   isLoggedIn = false; // 从 Vuex 获取登录状态
              // }
              if(getToken()){
               await customerInfo({id:getUserId()}).then(res => {
                console.log(res,"resres")
                if(res.error_msg){
                  isLoggedIn = false
                }else{
                  localStorage.setItem('userinfo',JSON.stringify(res.data))
                  console.log('ppp')
                  isLoggedIn = true
                }
                }).catch(err=>{
                  console.log('dddd')
                  isLoggedIn = false
                })
              }else{
                isLoggedIn = false
              }
              if (!isLoggedIn && to.name !== 'home') {
                console.log('sskkjss')
                next({ name: 'home' });
                this.$store.commit('showLoginModal',true); // 显示登录弹窗
              } else {
                next();
              }
            });
         },
         mounted() {
          this.activeName = this.$route.name
         },
         watch: {
          '$route' (to, from) {
              // to 和 from 是路由对象
              this.activeName = to.name
              console.log('Route changed:', to.path, from.path)
              // 在这里执行你想要做的事情
            }
         },
         methods: {
          handleClick(){
            this.$router.push({ name: this.activeName })
          },
          goTopersonInfo(){
            console.log('sssj')
            this.$router.push({ name: 'personinfo' })
          },
          loginSuccess(){
            this.$store.commit('showLoginModal',false); // 显示登录弹窗
            localStorage.setItem("showDrawDialog",'2')
            location.reload();
          },
          routerPush(router){
            console.log(router,"ssssaa")
            this.showRouter = router;
            // this.$set(this.showRouter, router);
            // this.$forceUpdate();
            
          },
          closeDialog(){
            this.$store.commit('showLoginModal',false); // 显示登录弹窗
          },
          handleCommand(command){
            console.log(command,"commandcommand")
            if(command == "loginout"){
              localStorage.removeItem('userinfo');
              localStorage.removeItem('token');
              removeToken()
              removeUserId()
              usrLogout({}).then(res=>{
                this.$router.push({ name: 'home' })
                this.$store.commit('showLoginModal',true); // 显示登录弹窗
                this.userInfo = null
              })
            }else{
              this.$router.push({ name:command })
            }
          },
          showLoginDialog(type){
            // this.showLoginModal = true
            this.$store.commit('showLoginModal',true); // 显示登录弹窗
            this.showRouter = type
          },
         }
     }
   </script>
   
   <style lang="scss" scoped>
     .header
     {
        width: 1440px;
        margin: 0 auto;
        height: 64px;
        background: #1F4E98;
        display: flex;
        color: #fff;
        justify-content:space-between;
        align-content: center;
        .leftLogo{
          display: flex;
          align-content: center;
          img{
            width: 32px;
            height: 32px;
            margin-top: 20px;
          }
          div{
            font-size: 16px;
            font-family: Source Han Sans SC-Medium, Source Han Sans SC;
            font-weight: 500;
            color: #fff;
            line-height: 64px;
          }
        }
        .menu{

        }
        .userinfo{

          .unlogin{
            margin-top: 16px;
            span{
              width: 85px;
              height: 32px;
              line-height: 32px;
              border-radius: 20px 20px 20px 20px;
              text-align: center;
              display:inline-block;
              font-size: 14px;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: rgba(38,38,38,0.85);
            }
            .singnup{
              width: 85px;
              margin-left: 16px;
              height: 32px;
              background: #FFFFFF;
              border-radius: 20px 20px 20px 20px;
              opacity: 1;
              text-align: center;
              line-height: 32px;
              border: 1px solid #CCCFD6;
            }
          }
          .logined{
            display: flex;
            .userImg{
              width: 32px;
              height: 32px;
              cursor: pointer;
              border-radius: 50%;
              margin-top: 16px;
            }
            span{
              display: inline-block;
              margin:0px 16px;
              font-size: 16px;
              height: 64px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: rgba(38,38,38,0.85);
              line-height: 64px;
            }
            .bellImg{
              width: 16px;
              height: 16px;
              margin-top: 24px;
            }
          }

        }

     ::v-deep .el-tabs__nav{
      height: 64px;
      line-height: 64px;
     }
     ::v-deep .el-tabs__nav-wrap::after{
        background: #1F4E98;
     }

     ::v-deep  .el-tabs__active-bar{
      background-color: #fff;
     }
     ::v-deep .el-tabs__item{
      color: #fff;
     }
     }
.topInfo{
  width: 238px;
  height: 78px;
  background: #FFFFFF;
  border-radius: 0px 0px 0px 0px;
  display: flex;
  align-items: center;
  .leftimg{
    width: 40px;
    height: 40px;
    margin-right: 8px;
    border-radius: 50%;
  }
  .ringht{
    .nickName{
      height: 23px;
      font-size: 15px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(38,38,38,0.85);
      line-height: 23px;
      .vipinfo{
        width: 35px;
      }
    }
  }
}

.mycenterItem{
  display: flex;
  height: 56px;
  img{
    width: 20px;
    margin-right: 8px;
  }
  .ItemLaber{
    font-size: 16px;
    line-height: 56px;
    font-family: Source Han Sans SC-Regular, Source Han Sans SC;
    font-weight: 400;
    color: #333333;
  }
}
::v-deep .el-dialog__body{
  padding: 0px;
}
::v-deep .el-dialog__header{
  display: none;
  padding: 0px;
}
   </style>
   
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { width: "100%", background: "#1F4E98" } }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "menu" },
          [
            _c(
              "el-tabs",
              {
                on: { "tab-click": _vm.handleClick },
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              [
                _c("el-tab-pane", { attrs: { label: "Home", name: "home" } }),
                _c("el-tab-pane", { attrs: { label: "Order", name: "order" } }),
                _c("el-tab-pane", {
                  attrs: { label: "Sell", name: "sellCard" },
                }),
                _c("el-tab-pane", {
                  attrs: { label: "Reward", name: "invitation" },
                }),
                _c("el-tab-pane", {
                  attrs: { label: "Wallet", name: "wallet" },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "userinfo" }, [
          !_vm.userInfo
            ? _c("div", { staticClass: "unlogin" }, [
                _c(
                  "span",
                  {
                    staticStyle: {
                      cursor: "pointer",
                      color: "#fff",
                      border: "1px solid #fff",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.showLoginDialog("login")
                      },
                    },
                  },
                  [_vm._v("Sign in")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "singnup",
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function ($event) {
                        return _vm.showLoginDialog("create")
                      },
                    },
                  },
                  [_vm._v("Sign Up")]
                ),
              ])
            : _c(
                "div",
                { staticClass: "logined" },
                [
                  _c("img", {
                    staticClass: "userImg",
                    attrs: { src: _vm.userInfo.avatar, alt: "" },
                    on: { click: _vm.goTopersonInfo },
                  }),
                  _c(
                    "el-dropdown",
                    {
                      attrs: { trigger: "click" },
                      on: { command: _vm.handleCommand },
                    },
                    [
                      _c(
                        "span",
                        { staticStyle: { cursor: "pointer", color: "#fff" } },
                        [
                          _vm._v(" " + _vm._s(_vm.userInfo.nickname)),
                          _c("i", {
                            staticClass: "el-icon-arrow-down el-icon--right",
                          }),
                        ]
                      ),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          _c("el-dropdown-item", [
                            _c("div", { staticClass: "topInfo" }, [
                              _c("img", {
                                staticClass: "leftimg",
                                attrs: { src: _vm.userInfo.avatar, alt: "" },
                              }),
                              _c("div", { staticClass: "ringht" }, [
                                _c("div", { staticClass: "nickName" }, [
                                  _vm._v(_vm._s(_vm.userInfo.nickname) + " "),
                                  _c("img", {
                                    staticClass: "vipinfo",
                                    attrs: {
                                      src: require(`@/assets/common/vip${_vm.userInfo.vip}Id.svg`),
                                      alt: "",
                                    },
                                  }),
                                ]),
                                _c("div", [_vm._v(_vm._s(_vm.userInfo.email))]),
                              ]),
                            ]),
                          ]),
                          _c(
                            "el-dropdown-item",
                            { attrs: { divided: "", command: "personinfo" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "mycenterItem",
                                  on: {
                                    cilck: function ($event) {
                                      return _vm.goRouter("personinfo")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/common/setting.svg"),
                                      alt: "",
                                    },
                                  }),
                                  _c("div", { staticClass: "ItemLaber" }, [
                                    _vm._v("Account settings"),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "el-dropdown-item",
                            { attrs: { command: "safety" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "mycenterItem",
                                  on: {
                                    cilck: function ($event) {
                                      return _vm.goRouter("safety")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/common/security.svg"),
                                      alt: "",
                                    },
                                  }),
                                  _c("div", { staticClass: "ItemLaber" }, [
                                    _vm._v("Security"),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "el-dropdown-item",
                            { attrs: { command: "certification" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "mycenterItem",
                                  on: {
                                    cilck: function ($event) {
                                      return _vm.goRouter("certification")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/common/certification.svg"),
                                      alt: "",
                                    },
                                  }),
                                  _c("div", { staticClass: "ItemLaber" }, [
                                    _vm._v("Certification"),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "el-dropdown-item",
                            { attrs: { command: "vipinfo" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "mycenterItem",
                                  on: {
                                    cilck: function ($event) {
                                      return _vm.goRouter("vipinfo")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/common/vipimg.svg"),
                                      alt: "",
                                    },
                                  }),
                                  _c("div", { staticClass: "ItemLaber" }, [
                                    _vm._v("Vip level"),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "el-dropdown-item",
                            { attrs: { command: "helpcenter" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "mycenterItem",
                                  on: {
                                    cilck: function ($event) {
                                      return _vm.goRouter("helpcenter")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/common/help.svg"),
                                      alt: "",
                                    },
                                  }),
                                  _c("div", { staticClass: "ItemLaber" }, [
                                    _vm._v("Help center"),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "el-dropdown-item",
                            { attrs: { command: "contentus" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "mycenterItem",
                                  on: {
                                    cilck: function ($event) {
                                      return _vm.goRouter("contentus")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/common/contact.svg"),
                                      alt: "",
                                    },
                                  }),
                                  _c("div", { staticClass: "ItemLaber" }, [
                                    _vm._v("Contact us"),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "el-dropdown-item",
                            { attrs: { divided: "", command: "loginout" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "mycenterItem",
                                  on: {
                                    cilck: function ($event) {
                                      return _vm.goRouter()
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/common/loginout.svg"),
                                      alt: "",
                                    },
                                  }),
                                  _c("div", { staticClass: "ItemLaber" }, [
                                    _vm._v("Sign out"),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("img", {
                    staticClass: "bellImg",
                    attrs: {
                      src: require("../assets/common/bell.svg"),
                      alt: "",
                    },
                  }),
                ],
                1
              ),
        ]),
        _vm.showLoginModal
          ? _c(
              "el-dialog",
              {
                attrs: {
                  visible: _vm.showLoginModal,
                  "show-close": false,
                  modal: false,
                  "destroy-on-close": true,
                  "close-on-click-modal": false,
                  width: "1060px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.showLoginModal = $event
                  },
                },
              },
              [
                _vm.showRouter == "login"
                  ? _c("Login", {
                      on: {
                        loginSuccess: _vm.loginSuccess,
                        routerPush: _vm.routerPush,
                        closeDialog: _vm.closeDialog,
                      },
                    })
                  : _vm._e(),
                _vm.showRouter == "create"
                  ? _c("Register", {
                      on: {
                        closeDialog: _vm.closeDialog,
                        routerPush: _vm.routerPush,
                      },
                    })
                  : _vm._e(),
                _vm.showRouter == "forget"
                  ? _c("ForgetPsd", {
                      on: {
                        closeDialog: _vm.closeDialog,
                        routerPush: _vm.routerPush,
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "leftLogo" }, [
      _c("img", {
        attrs: { src: require("@/assets/common/ngnlogo.png"), alt: "" },
      }),
      _c("div", [_vm._v("NGNCARD")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
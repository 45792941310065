var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login" }, [
    _vm._m(0),
    _c("div", { staticClass: "right" }, [
      _c("div", { staticClass: "loginInfo" }, [
        _vm._m(1),
        _c(
          "div",
          { staticClass: "loginForm" },
          [
            _c(
              "el-form",
              {
                staticClass: "demo-form-inline",
                attrs: { inline: true, model: _vm.loginForm, rules: _vm.rules },
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-input",
                          {
                            attrs: { placeholder: "Name" },
                            model: {
                              value: _vm.loginForm.username,
                              callback: function ($$v) {
                                _vm.$set(_vm.loginForm, "username", $$v)
                              },
                              expression: "loginForm.username",
                            },
                          },
                          [
                            _c("img", {
                              staticStyle: {
                                width: "20px",
                                "margin-top": "6px",
                              },
                              attrs: {
                                slot: "prefix",
                                src: require("../../assets/image/username.png"),
                                alt: "",
                              },
                              slot: "prefix",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-input",
                          {
                            attrs: {
                              placeholder: "Password",
                              "show-password": "",
                            },
                            model: {
                              value: _vm.loginForm.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.loginForm, "password", $$v)
                              },
                              expression: "loginForm.password",
                            },
                          },
                          [
                            _c("img", {
                              staticStyle: {
                                width: "20px",
                                "margin-top": "6px",
                              },
                              attrs: {
                                slot: "prefix",
                                src: require("../../assets/image/password.png"),
                                alt: "",
                              },
                              slot: "prefix",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "otherBtn" },
                  [
                    _c(
                      "el-radio",
                      {
                        attrs: { label: 1 },
                        model: {
                          value: _vm.remeberRadio,
                          callback: function ($$v) {
                            _vm.remeberRadio = $$v
                          },
                          expression: "remeberRadio",
                        },
                      },
                      [_vm._v("Remember me")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "forgotBtn",
                        on: {
                          click: function ($event) {
                            return _vm.routerPush("forget")
                          },
                        },
                      },
                      [_vm._v("Forgot password?")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { staticStyle: { "margin-top": "40px" } },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "loginBtn",
                        attrs: { type: "primary" },
                        on: { click: _vm.onSubmit },
                      },
                      [_vm._v("login")]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "createBtn" }, [
                  _c("span", [_vm._v("I don't have an account？")]),
                  _c(
                    "span",
                    {
                      staticClass: "create",
                      on: {
                        click: function ($event) {
                          return _vm.routerPush("create")
                        },
                      },
                    },
                    [_vm._v("Create an account")]
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("img", {
        staticClass: "closeBtn",
        attrs: { src: require("@/assets/common/close.svg"), alt: "" },
        on: { click: _vm.closeDialog },
      }),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left" }, [
      _c("div", { staticClass: "leftinfo" }, [
        _c("img", {
          attrs: { src: require("@/assets/common/ngnwhitelogo.svg"), alt: "" },
        }),
        _c("div", { staticClass: "infoTitle" }, [
          _c("div", [_vm._v("Welcome to")]),
          _c("div", [_vm._v("Ngncard")]),
        ]),
        _c("div", { staticClass: "infoAbstract" }, [
          _vm._v(
            " A world-class gift card trading platform that processes over $1 million in cards per day "
          ),
        ]),
        _c("div", { staticClass: "infoLine" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "infoTopTitle" }, [
      _c("div", [_vm._v(" Sign In ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
import getData from './axios'
import CONSTANTS from '../constants'

export default {
  getSearchCondition: (param) =>getData({
    url: `/platform/home/resource`,
    method: "GET",
    data: param,
  }),
  getRequest: (param) => getData({
    method: "GET",
    url: param.url,
    data: param.data,
    responseType: param.responseType,
    showError: param.showError // 显示错误提示
  }),
  postRequest: (param) => getData({
    method: "POST",
    url: param.url,
    data: param.data,
    responseType: param.responseType,
    showError: param.showError
  }),
  // formDataRequest:(param)=> getData({
  //
  // }),
  delRequest: (param)  => getData({
    method: "DELETE",
    url: param.url,
    data: param.data,
    parameter: param.parameter, // 用于处理数组
    showError: param.showError
  }),
  putRequest: (param)  =>{
  //   let data = new FormData()
  //   data.append('institutionAttrList',param.data)
    return  getData({
      method: "PUT",
      url: param.url,
      data: param.data,
      showError: param.showError
    })
  }
};

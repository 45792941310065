import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
// 解决编程式路由往同一地址跳转时会报错的情况 为解决搜索列表点击有时候报错问题
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch((err) => err);
};
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalReplace.call(this, location, onResolve, onReject);
  }
  return originalReplace.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    component: () => import("@/views/home.vue"),
    name: "home",
    hidden: true,
  },
  {
    path: "/sellCard",
    component: () => import("@/views/sellCard.vue"),
    name: "sellCard",
    hidden: true,
  },
  //订单
  {
    path: "/order",
    component: () => import("@/views/order/index.vue"),
    name: "order",
    hidden: true,
  },
  {
    path: "/orderDetail",
    component: () => import("@/views/order/orderDetail.vue"),
    hidden: true,
  },
  //钱包
  {
    path: "/wallet",
    component: () => import("@/views/wallet/index.vue"),
    name: "wallet",
    hidden: true,
  },
  //邀请
  {
    path: "/invitation",
    component: () => import("@/views/invitation/index.vue"),
    name: "invitation",
    hidden: true,
  },
  //评论
  {
    path: "/comment",
    component: () => import("@/views/order/commitOrder.vue"),
    name: "comment",
    hidden: true,
  },
  {
    path: "/commmitDetail",
    component: () => import("@/views/order/commmitDetail.vue"),
    name: "commmitDetail",
    hidden: true,
  },
  {
    path: "/mycenter",
    component: () => import("../views/my/index"),
    children: [
      {
        path: "",
        name: "personinfo",
        component: () => import("../views/my/personinfo")
      },
      {
        path: "/safety",
        name: "safety",
        component: () => import("../views/my/safety")
      },
      {
        path: "/certification",
        name: "certification",
        component: () => import("../views/my/certification")
      },
      {
        path: "/vipinfo",
        name: "vipinfo",
        component: () => import("../views/my/vipinfo")
      },
      {
        path: "/commit",
        name: "commit",
        component: () => import("../views/my/commit")
      },
      {
        path: "/feedback",
        name: "feedback",
        component: () => import("../views/my/feedback")
      },
      {
        path: "/helpcenter",
        name: "helpcenter",
        component: () => import("../views/my/helpcenter")
      },
      {
        path: "/contentus",
        name: "contentus",
        component: () => import("../views/my/contentus")
      },
    ]
  },
  {
    path: "/helpCenterDetail",
    component: () => import("@/views/my/helpCenterDetail.vue"),
    hidden: true,
  },
];

const router = new VueRouter({
  routes,
});

export default router;

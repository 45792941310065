import API from "../api";
const state = {
  dictData: [],
};
const getters = {
  //   getDicDataFromDic: (state) => (group) => {
  //     if (state.dictData.length === 0) {
  //       return defaultDict.result.filter((dic) => dic.dictGroup === group);
  //     }
  //     return state.dictData.filter((dic) => dic.dictGroup === group);
  //   },
};
const mutations = {
  initJdDict(state, dict) {
    state.dictData = dict;
  },
};
const actions = {
  getDicts(context) {
    API.getRequest({ url: "/esim/retrieve/getDict", data: null }).then(
      (res) => {
        if (res.code === "SUC000000") {
          context.commit("initJdDict", res.result);
        }
      }
    );
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

// obj key 
// refreshToken vux中刷新token的函数  
// filterUrl Array 不需要拦截的请求路径
/* 刷新token 2021-09-29*/
export function interceptors(axios, store, obj) {
  const content = window
  // 防止重复刷新
  let isRefreshing = false;
  // 缓存token失效后 所有接口
  let subscribers = [];
  // 刷新 token 后, 将缓存的接口重新请求一次
  function onAccessTokenFetched(newToken) {
    subscribers.forEach((callback) => {
      callback(newToken);
    });
    // 清空缓存接口
    subscribers = [];
  }
  // 添加缓存接口
  function addSubscriber(callback) {
    subscribers.push(callback);
  }


  axios.interceptors.response.use(response => {
    let fliterFlag = obj.filtersUrl ? !obj.filtersUrl.some(item => response.config.url.includes(item)) : true
    if (response.data.code === "A0230" && fliterFlag) {
      if (!isRefreshing) {
        isRefreshing = true;
        // 将刷新token的方法放在vuex中处理了, 可见下面区块代码
        store.dispatch(obj.refreshToken).then((res) => {
          if (res.code === "200") {
            // 当刷新成功后, 重新发送缓存请求
            onAccessTokenFetched(res.result.jwt_token);
          } else {
           content.top.postMessage({
             type:"LOGOUT"
           })
          }
        }).catch(() => {
          // 刷新token报错的话, 就需要跳转到登录页面
          content.top.postMessage({
            type:"LOGOUT",
            data:{}
          })
        }).finally(() => {
          isRefreshing = false;
        });
      }
      const retryOriginalRequest = new Promise((resolve) => {
        // 这里是将其他接口缓存起来的关键, 返回Promise并且让其状态一直为等待状态, 
        // 只有当token刷新成功后, 就会调用通过addSubscriber函数添加的缓存接口, 
        // 此时, Promise的状态就会变成resolve
        addSubscriber((newToken) => {
          // 表示用新的token去替换掉原来的token
          response.config.headers.Authorization = `bearer ${newToken}`;
          // 替换掉url -- 因为baseURL会扩展请求url
          // response.config.url = response.config.url.replace(response.config.baseURL, '');
          // 用重新封装的config去请求, 就会将重新请求后的返回
          resolve(axios(response.config));

        });
      });
      return retryOriginalRequest;
    }
    return response
  })
}

import Vue from "vue";
import Vuex from "vuex";
import jdDictData from "./jdDictData";
import search from '@/store/search'
import channel from '@/store/channel'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    detailOpt: true,
    isLoggedIn: false,  // 你可以通过 API 调用或其他方法来设置这个状态
    showLoginModal: false
  },
  getters: {},
  mutations: {
    showLoginModal(state,status) {
      state.showLoginModal = status;
    },
    showisLoggedIn(state, data) {
      state.isLoggedIn = data;
    }
  },
  actions: {},
  modules: {
    jdDictData,
    search,
    channel
  },
});

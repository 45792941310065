var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { background: "#D5E3F5" } },
    [
      _c("MyHeader", {
        staticStyle: { position: "fixed", top: "0px", "z-index": "999" },
      }),
      _c("router-view", {
        staticStyle: {
          width: "1440px",
          margin: "0 auto",
          "margin-top": "64px",
        },
      }),
      _c("MyFooter"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { width: "100%", background: "#1F4E98" } }, [
    _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "footerLeft" }, [
        _c("div", { staticClass: "terms" }, [
          _c("div", { staticClass: "newTitle" }, [_vm._v(" Twitter ")]),
          _c("div", { staticClass: "newsubTitle" }, [
            _c(
              "div",
              {
                staticStyle: { cursor: "pointer" },
                on: {
                  click: function ($event) {
                    return _vm.openNewPage(_vm.twitterInfo.description)
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.twitterInfo.value) + " ")]
            ),
          ]),
          _c(
            "div",
            { staticClass: "newTitle", staticStyle: { "margin-top": "32px" } },
            [_vm._v(" Instagram ")]
          ),
          _c("div", { staticClass: "newsubTitle" }, [
            _c(
              "div",
              {
                staticStyle: { cursor: "pointer" },
                on: {
                  click: function ($event) {
                    return _vm.openNewPage(_vm.instagram.description)
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.instagram.value) + " ")]
            ),
          ]),
        ]),
        _c("div", { staticClass: "aboutUs" }, [
          _c("div", { staticClass: "newTitle" }, [_vm._v(" Faceboook ")]),
          _c("div", { staticClass: "newsubTitle" }, [
            _c(
              "div",
              {
                staticStyle: { cursor: "pointer" },
                on: {
                  click: function ($event) {
                    return _vm.openNewPage(_vm.facebookInfo.description)
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.facebookInfo.value) + " ")]
            ),
          ]),
          _c(
            "div",
            { staticClass: "newTitle", staticStyle: { "margin-top": "32px" } },
            [_vm._v(" TikTok ")]
          ),
          _c("div", { staticClass: "newsubTitle" }, [
            _c(
              "div",
              {
                staticStyle: { cursor: "pointer" },
                on: {
                  click: function ($event) {
                    return _vm.openNewPage(_vm.tiktok.description)
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.tiktok.value) + " ")]
            ),
          ]),
        ]),
        _c("div", { staticClass: "aboutUs" }, [
          _c("div", { staticClass: "newTitle" }, [_vm._v(" Whats app ")]),
          _c("div", { staticClass: "newsubTitle" }, [
            _c(
              "div",
              {
                staticStyle: { cursor: "pointer" },
                on: {
                  click: function ($event) {
                    return _vm.openNewPage(_vm.whatsappinfoList[0].description)
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.whatsappinfoList[0].value) + " ")]
            ),
          ]),
          _c(
            "div",
            { staticClass: "newTitle", staticStyle: { "margin-top": "32px" } },
            [_vm._v(" Whats app ")]
          ),
          _c("div", { staticClass: "newsubTitle" }, [
            _c(
              "div",
              {
                staticStyle: { cursor: "pointer" },
                on: {
                  click: function ($event) {
                    return _vm.openNewPage(_vm.whatsappinfoList[1].description)
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.whatsappinfoList[1].value) + " ")]
            ),
          ]),
        ]),
      ]),
      _vm._m(0),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "footerfight" }, [
      _c("div", { staticClass: "topLogo" }, [
        _c("img", {
          attrs: { src: require("../assets/common/ngnlogo.png"), alt: "" },
        }),
        _c("div", { staticStyle: { color: "#fff" } }, [_vm._v(" Ngncard ")]),
      ]),
      _c("div", { staticClass: "ngnDetail" }, [
        _c("div", { staticStyle: { color: "#FCFCFC" } }, [
          _vm._v("©2023 NGNCARD LIMITED. All Rights Reserved."),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
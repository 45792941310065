import getData from './axios'


export function selectProductList(data) {

  return getData({
    url: `/admin/product/selectProductList`,
    method: 'POST',
    data: data,
    params: data,
    noToken: true
  })
}


export function selectProductTemplate(data) {

    return getData({
      url: `/admin/productTemplate/selectProductTemplate`,
      method: 'POST',
      data: data,
      params: data,
      noToken: true
    })
  }

export function selectProductTemplateList(data) {

    return getData({
      url: `/admin/productTemplate/selectProductTemplateList`,
      method: 'POST',
      data: data,
      params: data,
      noToken: true
    })
  }

  export function selectBestSellingCards(data) {

    return getData({
      url: `/admin/productTemplate/selectBestSellingCards`,
      method: 'POST',
      data: data,
      params: data,
      noToken: true
    })
}


  export function loginByUser(data) {
    return getData({
      url: `/auth/oauth/token?grant_type=password&scope=server`,
      method: 'POST',
      data: data,
      noToken: true
    })
}
export function refreshToken(data) {
  return getData({
    url: `/auth/oauth/token?grant_type=refresh_token&scope=server&&refresh_token=`,
    method: 'POST',
    data: data,
    noToken: true
  })
}

export function usrLogout(data) {
  return getData({
    url: `/auth/token/logout`,
    method: 'delete',
    data: data,
    noToken: true
  })
}


export function sendCaptcha(data) {
  return getData({
    url: `/admin/customer/sendCaptcha`,
    method: 'POST',
    data: data,
    noToken: true
  })
}

export function selectCountryCodeList(data) {
  return getData({
    url: `/admin/countryCode/selectCountryCodeList`,
    method: 'POST',
    data: data,
    noToken: true
  })
}


export function register(data) {
  return getData({
    url: `/admin/customer/register`,
    method: 'POST',
    data: data,
    noToken: true
  })
}

export function forgetPassword(data) {
  return getData({
    url: `/admin/customer/forgetPassword`,
    method: 'POST',
    data: data,
    noToken: true
  })
}


export function uploadFile(path,data) {
  return getData({
    url: `/admin/storage/create?path=` + path,
    method: 'POST',
    data: data,
    noToken: true
  })
}

export function selectBankList(data) {
  return getData({
    url: `/admin/bank/selectBankList`,
    method: 'POST',
    data: data,
    noToken: true
  })
}

export function CurrentSupportCurrency(data) {
  return getData({
    url: `/admin/currentSupportCurrency/selectCurrentSupportCurrency`,
    method: 'POST',
    data: data,
    noToken: true
  })
}

export function saveSellerOrder(data) {
  return getData({
    url: `/admin/sellerOrder/saveSellerOrder`,
    method: 'POST',
    data: data,
    noToken: true
  })
}

export function selectProductTemplateById(data) {
  return getData({
    url: `/admin/productTemplate/selectProductTemplateById`,
    method: 'POST',
    data: data,
    noToken: true
  })
}

//钱包相关
export function saveBank(data) {
  return getData({
    url: `/admin/bank/saveBank`,
    method: 'POST',
    data: data,
    noToken: true
  })
}

//订单

export function selectSellerOrder(data) {
  return getData({
    url: `/admin/sellerOrder/selectSellerOrder`,
    method: 'POST',
    data: data,
    noToken: true
  })
}


export function selectSellerOrderById(data) {
  return getData({
    url: `/admin/sellerOrder/selectSellerOrderById`,
    method: 'POST',
    data: data,
    noToken: true
  })
}


export function cancelSellerOrder(data) {
  return getData({
    url: `/admin/sellerOrder/cancelSellerOrder`,
    method: 'POST',
    data: data,
    noToken: true
  })
}

//钱包相关
export function CurrentSupportCurrencyRate(data) {
  return getData({
    url: `/admin/currentSupportCurrencyRate/selectCurrentSupportCurrencyRate`,
    method: 'POST',
    data: data,
    noToken: true
  })
}


export function walletInfo(data) {
  return getData({
    url: `/admin/customer/walletInfo`,
    method: 'POST',
    data: data,
    noToken: true
  })
}

export function deleteBank(data) {
  return getData({
    url: `/admin/bank/deleteBank`,
    method: 'POST',
    data: data,
    noToken: true
  })
}


export function selectBillRecord(data) {
  return getData({
    url: `/admin/billRecord/selectCustomerBillRecord`,
    method: 'POST',
    data: data,
    noToken: true
  })
}

export function withdraw(data) {
  return getData({
    url: `/admin/billRecord/withdraw`,
    method: 'POST',
    data: data,
    noToken: true
  })
}

export function transfer(data) {
  return getData({
    url: `/admin/billRecord/transfer`,
    method: 'POST',
    data: data,
    noToken: true
  })
}

//邀请
export function selectInviteRecord(data) {
  return getData({
    url: `/admin/inviteRecord/selectCustomerInviteRecord`,
    method: 'POST',
    data: data,
    noToken: true
  })
}
//个人中心
export function customerInfo(data) {
  return getData({
    url: `/admin/customer/customerInfo`,
    method: 'POST',
    data: data,
    noToken: true
  })
}


export function updateCustomer(data) {
  return getData({
    url: `/admin/customer/updateCustomer`,
    method: 'POST',
    data: data,
    noToken: true
  })
}


export function updatePhone(data) {
  return getData({
    url: `/admin/customer/updatePhone`,
    method: 'POST',
    data: data,
    noToken: true
  })
}


export function updateEmail(data) {
  return getData({
    url: `/admin/customer/updateEmail`,
    method: 'POST',
    data: data,
    noToken: true
  })
}
export function updatePayment(data) {
  return getData({
    url: `/admin/customer/updatePayment`,
    method: 'POST',
    data: data,
    noToken: true
  })
}
export function updatePassword(data) {
  return getData({
    url: `/admin/customer/updatePassword`,
    method: 'POST',
    data: data,
    noToken: true
  })
}


export function saveCertification(data) {
  return getData({
    url: `/admin/certification/saveCertification`,
    method: 'POST',
    data: data,
    noToken: true
  })
}


export function selectVipLevelList(data) {
  return getData({
    url: `/admin/vipLevel/selectVipLevelList`,
    method: 'POST',
    data: data,
    noToken: true
  })
}

export function selectHelpCenterList(data) {
  return getData({
    url: `/admin/helpCenter/selectHelpCenterList`,
    method: 'POST',
    data: data,
    noToken: true
  })
}

export function selectHelpCenterById(data) {
  return getData({
    url: `/admin/helpCenter/selectHelpCenterById`,
    method: 'POST',
    data: data,
    noToken: true
  })
}


export function selectCustomerEvaluation(data) {
  return getData({
    url: `/admin/evaluation/selectCustomerEvaluation`,
    method: 'POST',
    data: data,
    noToken: true
  })
}


export function saveEvaluation(data) {
  return getData({
    url: `/admin/evaluation/saveEvaluation`,
    method: 'POST',
    data: data,
    noToken: true
  })
}


export function selectEvaluationById(data) {
  return getData({
    url: `/admin/evaluation/selectEvaluationById`,
    method: 'POST',
    data: data,
    noToken: true
  })
}

export function getBanner(data) {
  return getData({
    url: `/admin/banner/selectBannerList`,
    method: 'POST',
    data: data,
    noToken: true
  })
}


//首页评价截图
export function selectHomeImageList(data) {
  return getData({
    url: `/admin/homeImage/selectHomeImageList`,
    method: 'POST',
    data: data,
    noToken: true
  })
}
//意见反馈
export function saveFeedback(data) {
  return getData({
    url: `/admin/feedback/saveFeedback`,
    method: 'POST',
    data: data,
    noToken: true
  })
}

//抽奖
export function dealLotteryActivity(data) {
  return getData({
    url: `/admin/customer/dealLotteryActivity`,
    method: 'POST',
    data: data,
    noToken: true
  })
}

export function getRaffle(data) {
  return getData({
    url: `/admin/dict/getRaffle`,
    method: 'GET',
    data: data,
    noToken: true
  })
}


export function getHomeFooter(data) {
  return getData({
    url: `/admin/dict/getHomeFooter`,
    method: 'GET',
    data: data,
    noToken: true
  })
}
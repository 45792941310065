import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from "element-ui";
import dayjs from 'dayjs'
import "element-ui/lib/theme-chalk/index.css";
import locale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n
import '@/styles/index.scss' // global css
import API from './api'
/* 刷新token 2021-09-29*/
import axios from 'axios'
import { interceptors } from '@/api/rokenRefresh'
import directives from '@/utils/directives.js'
import EventBus from '@/utils/eventBus.js'


// 引入 vue-awesome-swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
 
//引入 vue-awesome-swiper 样式
import 'swiper/dist/css/swiper.css'


import VueLuckyCanvas from '@lucky-canvas/vue'
Vue.use(VueLuckyCanvas)

Vue.use(VueAwesomeSwiper)

interceptors(axios,store,{refreshToken:'user/refreshToken',filtersUrl:["oauth"]})
let title = document.getElementsByTagName('title')[0]
import './components/index';
title.innerText = 'NGN | GOOD RATE'

// import './styles/element-variables.scss'
Vue.config.productionTip = false
Vue.prototype.$dayjs = dayjs
Vue.prototype.$EventBus = EventBus
// set ElementUI lang to EN
// set ElementUI lang to EN
Vue.use(ElementUI, {
  locale,
  size: 'small'
})

router.afterEach((to,from,next)=>{window,scrollTo(0,0)})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

const state = {
    publishOrgList:[],
    publishList:[],
}
const mutations = {
    SET_ORG_LIST(state,data){
        state.publishOrgList = data
    },
    SET_PUBLISH_LIST(state,data){
        state.publishList = data
    },
}   
const actions = {
    setOrgList({commit},orgList){
        console.log(orgList,"orgListorgListorgListorgList")
        commit('SET_ORG_LIST',orgList)
    },
    setPubulisList({commit},publishList){
        console.log(publishList,"orgListorgListorgListorgList")
        commit('SET_PUBLISH_LIST',publishList)
    },
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
  }
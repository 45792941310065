import axios from "axios";
import CONSTANTS from "../constants";
import { Message } from "element-ui";
import { getToken,removeUserId,removeToken,setToken,setUserId} from "@/utils/auth";
import { refreshToken } from "@/api/giftcard";
import router from '../router'
import Store from '../store'
import qs from "qs";

// var ifToken = false

/**
 * @param {*} obj 请求配置
 */
axios.defaults.timeout = 100 * 1000;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.message.includes("timeout")) {
      // 判断请求异常信息中是否含有超时timeout字符串
      Message.warning("请求超时！");
      return Promise.reject(error); // reject这个错误信息
    }
    return Promise.reject(error);
  }
);

const axiosRequest = (obj) => {
  return axios(obj)
    .then((res) => {
      if (res.status === 200 && res.data) {
        if (res.data.code == 1) {
          Message.error(res.data.msg);
          return Promise.reject(res);
        }
          return res.data;
      } else {
        return {
          error_code: -1,
          error_msg: res.message || res.msg || "获取失败",
        };
      }
    })
    .catch((e) => {
          if(e.response.status == 424){
            localStorage.removeItem('token');
            removeToken()
            removeUserId()
            refreshToken(localStorage.getItem('refresh_token')).then(res => {
              console.log('res111111111',res)
              localStorage.setItem('token', res.access_token)
              localStorage.setItem('refresh_token', res.refresh_token)
              setToken(res.access_token)
              setUserId(res.user_info.id)
            })
        }else if(e.response.status == 426){
            Message.error(e.response.data.msg);
            // router.push('/')
            Store.commit('showLoginModal',true); // 显示登录弹窗
        }else{
            Message.error(e.response.data.msg);
        }
          if (e.response) {
        return {
          error_code: -1,
          error_msg: "网络异常 ：" + e.response.status,
        };
      } else {
        return {
          error_code: -1,
          error_msg: "网络异常",
        };
      }
    });
};

/**
 * @param {Object} config 请求配置
 */
const getData = (config, needLogin) => {
  if (config.url.indexOf("http") !== 0) {
    // config.url = CONSTANTS.API_BASE + config.url
    config.baseURL = CONSTANTS.API_BASE;
  }
  let requestData = {
    method: config.method || "GET",
    url: config.url,
    params: config.data || {},
    data: {},
    responseType: config.responseType || "json",
    headers: config.headers || {},
    showError: config.showError || false,
  };
  if (config.url.indexOf('auth/oauth') < 0) {
    requestData.headers.Authorization = "Bearer " + getToken()
  }else{
    requestData.headers.Authorization = "Basic Y3VzdG9tZXI6Y3VzdG9tZXI="
  }
  if (config.method === "POST") {
    requestData.data = config.data;
  }
  return axiosRequest(requestData);
};

export default getData;

<template>
    <div class="login">
        <div class="left">
            <div class="leftinfo">
                <img src="@/assets/common/ngnwhitelogo.svg" alt="">
                <div class="infoTitle">
                    <div>Welcome to</div>
                    
                    <div>Ngncard</div>
                </div>
                <div class="infoAbstract">
                    A world-class gift card trading platform that processes over $1 million in cards per day
                </div>
                <div class="infoLine">

                </div>
            </div>
        </div>
        <div class="right"> 
            <div class="loginInfo">
                <div class="infoTopTitle">
                    <div>
                        Sign In
                    </div>
                    <!-- <div>
                        Professional gift card sales platform
                    </div> -->
                </div>
                <div class="loginForm">
                    <el-form :inline="true" :model="loginForm"  :rules="rules" class="demo-form-inline">
                        <div>
                            <el-form-item >
                                <el-input  v-model="loginForm.username" placeholder="Name">
                                    <img slot="prefix" style="width:20px;height20px;margin-top:6px;" src="../../assets/image/username.png" alt="">
                                </el-input>
                            </el-form-item>
                        </div>
                        <div>
                            <el-form-item >
                                <el-input  v-model="loginForm.password" placeholder="Password" show-password>
                                    <img slot="prefix" style="width:20px;height20px;margin-top:6px;" src="../../assets/image/password.png" alt="">
                                </el-input>
                            </el-form-item>
                        </div>
                        <div class="otherBtn">
                            <el-radio v-model="remeberRadio" :label="1">Remember me</el-radio>
                            <div class="forgotBtn" @click="routerPush('forget')">Forgot password?</div>
                        </div>
                        <el-form-item style="margin-top: 40px;">
                          <el-button class="loginBtn" type="primary" @click="onSubmit">login</el-button>
                        </el-form-item>
                        <div class="createBtn">
                            <span>I don't have an account？</span>
                            <span class="create" @click="routerPush('create')">Create an account</span>
                        </div>
                      </el-form>
                </div>
            </div>
            <img class="closeBtn" src="@/assets/common/close.svg" @click="closeDialog" alt="">
        </div>
    </div>
   </template>
   
   <script>
    import {loginByUser,customerInfo} from "../../api/giftcard";
    import {encryption} from "@/utils/utils.js";
    import { setToken,setUserId,getUserId} from '@/utils/auth'
    import qs from 'qs'
     export default {
         name: 'login',
         props: {
        
         },
         data() {
             return {
                loginForm: {
                    username: '',
                    password: ''
                },
                remeberRadio: 0,
                rules:{
                    username: [
                        { required: true, message: 'please enter your username', trigger: 'blur' },
                    ],
                    password: [
                        { required: true, message: 'please enter your password  ', trigger: 'blur' },
                    ],
                }
             }
         },
         watch: {
 
         },
         methods: {
            onSubmit() {
            console.log(this.loginForm,"dasdasdasdsds")
            const user = encryption({
              data: this.loginForm,
              key: 'thanks,pig4cloud',
              param: ['password']
            })
            let dataObj = qs.stringify({'username': user.username, 'password': user.password})

            loginByUser(dataObj).then(res => {
              if(res.access_token){
                this.$message.success('login success')
                localStorage.setItem('token', res.access_token)
                localStorage.setItem('refresh_token', res.refresh_token)
                setToken(res.access_token)
                setUserId(res.user_info.id)
                customerInfo({id:getUserId()}).then(res => {
                    localStorage.setItem('userinfo',JSON.stringify(res.data))
                    this.$emit('loginSuccess')
                })
              }
            })
            },
            routerPush(router){
                
                this.$emit('routerPush',router)
            },
            closeDialog(){
                this.$emit('closeDialog')
            }
         }
     }
   </script>
   
   <style lang="scss" scoped>
   .login{
    width: 1060px;
    height: 640px;
    background: #FFFFFF;
    box-shadow: 0px 36px 56px 5px rgba(39,122,246,0.14);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    display: flex;
    .left{
        background-image: url('../../assets/common/Group.svg');
        width: 400px;
        .leftinfo{
            margin: 84px 60px auto 24px;
            img{
                width: 96p;
                height: 96px;
                opacity: 1;
            }
            .infoTitle{
                
                font-size: 44px;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 48px;
            }
            .infoAbstract{
                margin: 16px 0px;
                width: 316px;
                height: 84px;
                margin-top: 210px;
                font-size: 20px;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 23px;
            }
            .infoLine{
                width: 53px;
                height: 6px;
                background: #FFFFFF;
                border-radius: 0px 0px 0px 0px;
                opacity: 1;
            }
        }

    }
    .right{
        width: 100%;
        .loginInfo{
            margin: auto 144px;
            .infoTopTitle{
                margin-top: 140px;
                :first-child{
                    height: 48px;
                    font-size: 32px;
                    font-family: PingFang SC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #000000;
                    line-height: 48px;
                }

            }
            .loginForm{
                margin-top: 40px;
                .otherBtn{
                    display: flex;
                    justify-content: space-between;
                    ::v-deep .el-radio__label{
                        height: 22px;
                        font-size: 14px;
                        font-family: Source Han Sans SC-Regular, Source Han Sans SC;
                        font-weight: 400;
                        color: #B7BDC3;
                        line-height: 22px;
                    }
                    .forgotBtn{
                        height: 24px;
                        font-size: 14px;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #B7BDC3;
                        line-height: 24px;
                        cursor: pointer;
                    }
                }
                .loginBtn{
                    width: 100%;
                    height: 32px;
                    color: #fff;
                    font-weight: 14px;
                    background: #1F8EF2;
                    border-radius: 4px 4px 4px 4px;
                    opacity: 1;
                }
                .createBtn{
                    text-align: center;
                    margin-top: 12px;
                    height: 24px;
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #B7BDC3;
                    line-height: 24px;
                    .create{
                        margin-left: 4px;
                        color: #1F8EF2;
                        cursor: pointer;
                    }
                }
                ::v-deep .el-form--inline .el-form-item{
                    width: 100%;
                    margin-bottom:10px;
                }
                ::v-deep .el-form-item__content{
                    width: 100%;
                }
            }
        }
    }
   }

   .closeBtn{
    position: absolute;
    right: 20px;
    top:20px;
    width: 32px;
    height: 32px;
    cursor: pointer;
   }

   ::v-deep .el-tabs__item{
    color:#333 !important;
   }

   </style>
   
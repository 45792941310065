<template>
    <div class="login">
        <div class="left">
            <div class="leftinfo">
                <img src="@/assets/common/ngnlogo.svg" alt="">
                <div class="infoTitle">Ngncard</div>
                <div class="infoAbstract">
                    A world-class gift card trading platform that processes over $1 million in cards per day
                </div>
                <div class="infoLine">

                </div>
            </div>
        </div>
        <div class="right"> 
            <div class="loginInfo">
                <div class="infoTopTitle">
                    <div>
                        Sign Up
                    </div>
                    <div>
                    </div>
                </div>
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="By email" name="email"></el-tab-pane>
                    <el-tab-pane label="By sms" name="sms"></el-tab-pane>
                </el-tabs>
                <div class="loginForm">
                    <el-form :inline="true" :model="registerForm" ref="formRef" :rules="rules" class="demo-form-inline">
                        <div>
                            <el-form-item prop="username" >
                                <el-input v-model="registerForm.username" placeholder="Enter your username">
                                    <img slot="prefix" style="width:20px;height20px;margin-top:6px;" src="../../assets/image/username.png" alt="">
                                </el-input>
                            </el-form-item>
                        </div>
                        <div v-if="activeName == 'email'">
                            <el-form-item  prop="email">
                                <el-input v-model="registerForm.email" placeholder="Enter your email address">
                                    <img slot="prefix" style="width:20px;height20px;margin-top:6px;" src="../../assets/image/email.png" alt="">
                                </el-input>
                            </el-form-item>
                        </div>
                        <div v-if="activeName == 'sms'" class="phoneNum">
                            <el-select v-model="registerForm.countrycode" style="width:150px;">
                                <el-option
                                    v-for="(item,index) in CountryCodeList"
                                    :key="index"
                                    :label="`+${item.code}`"
                                    :value="item.code">
                                </el-option>
                            </el-select>
                            <el-form-item  style="margin-right:0px" prop="phone">
                                <el-input v-model="registerForm.phone" placeholder="Enter your email address"></el-input>
                            </el-form-item>
                        </div>
                        <div class="codeMessage">
                            <el-form-item prop="code">
                                <el-input v-model="registerForm.code" placeholder="Input message code">
                                    <img slot="prefix" style="width:20px;height20px;margin-top:6px;" src="../../assets/image/safety.png" alt="">
                                </el-input>
                            </el-form-item>
                            <el-button class="sendCodeBtn"   @click.stop="sendcode" :disabled="btnText !='Send code'">{{btnText}}</el-button>
                        </div>
                        <div>
                            <el-form-item prop="password">
                                <el-input v-model="registerForm.password" placeholder="Enter your password" show-password>
                                    <img slot="prefix" style="width:20px;height20px;margin-top:6px;" src="../../assets/image/password.png" alt="">
                                </el-input>
                            </el-form-item>
                        </div>
                        <div>
                            <el-form-item prop="confirmPassword">
                                <el-input v-model="registerForm.confirmPassword" placeholder="Confirm your password" show-password>
                                    <img slot="prefix" style="width:20px;height20px;margin-top:6px;" src="../../assets/image/password.png" alt="">
                                </el-input>
                            </el-form-item>
                        </div>
                        <div>
                            <el-form-item prop="whatsapp">
                                <el-input v-model="registerForm.whatsapp" placeholder="Enter your whatsapp number">
                                    <img slot="prefix" style="width:20px;height20px;margin-top:6px;" src="../../assets/image/whatsapp.png" alt="">
                                </el-input>
                            </el-form-item>
                        </div>
                        <div class="otherBtn">
                            <el-radio v-model="remeberRadio" :label="1">l agrce to the Terms of Service and Privecy Policy</el-radio>
                        </div>
                        <el-form-item style="margin-top: 20px;">
                          <el-button class="loginBtn" type="primary" @click="onSubmit">Create account</el-button>
                        </el-form-item>
                      </el-form>
                </div>
            </div>
            <img class="closeBtn" src="@/assets/common/close.svg" @click="closeDialog" alt="">
        </div>

        <Verify
            @success="success"
            :mode="'pop'"
            :captchaType="'blockPuzzle'"
            :imgSize="{ width: '276px', height: '130px' }"
            ref="verify"
        ></Verify>
    </div>
   </template>
   
   <script>
    import {sendCaptcha,selectCountryCodeList,register} from "../../api/giftcard";
    import Verify from "@/components/verifition/Verify";
     export default {
         name: 'login',
         props: {
        
         },
         components: {
            Verify,
          },
         data() {
             return {
                registerForm: {
                    username:'',
                    email:'',
                    phone:'',
                    code:'',
                    password:'',
                    confirmPassword:'',
                    whatsapp:'',
                    countrycode:''
                },
                remeberRadio: 0,
                rules:{
                    username: [
                        { required: true, message: 'please enter your username', trigger: 'blur' },
                    ],
                    email: [
                        { required: true, message: 'please enter your email  ', trigger: 'blur' },
                    ],
                    phone: [
                        { required: true, message: 'please enter your phone', trigger: 'blur' },
                    ],
                    code: [
                        { required: true, message: 'please enter your code', trigger: 'blur' },
                    ],
                    password: [
                        { required: true, message: 'please enter your password  ', trigger: 'blur' },
                        { validator: this.validateConfirmPassword, trigger: "blur" }
                    ],
                    confirmPassword: [
                        { required: true, message: 'please enter your confirmPassword', trigger: 'blur' },
                        { validator: this.validateConfirmPassword, trigger: "blur" }
                    ],
                    whatsapp: [
                        { required: true, message: 'please enter your whatsapp  ', trigger: 'blur' },
                    ],
                },
                activeName:'email',
                CountryCodeList:[],
                btnText:'Send code',
                sendCodedataObj:{},
             }
         },
         watch: {
 
         },
         created(){
            this.getCountryCode()
         },
         methods: {
            validateConfirmPassword(rule, value, callback) {
            if (value !== this.registerForm.password) {
                callback(new Error("输入的密码不一致"));
            } else {
                callback();
            }
            },
            getCountryCode(){
              selectCountryCodeList({}).then(res => {
                console.log(res,"ressssss")
                this.CountryCodeList = res.data
                this.registerForm.countrycode = res.data[0].code
                console.log(res.data[0].code,"res.data[0].coderes.data[0].code")
                console.log(res)
              })
            },
            handleClick(){
            },
            sendcode(){
              this.sendCodedataObj ={}
              if(this.activeName == 'email'){
                if(!this.registerForm.email){
                  this.$message.warning('please enter email')
                  return
                }

                this.sendCodedataObj = {'username':this.registerForm.username,'email': this.registerForm.email}
              }else{
                if(!this.registerForm.phone){
                    this.$message.warning('please enter phone'); 
                  return
                }

                this.sendCodedataObj = {'username':this.registerForm.username,'code':this.registerForm.countrycode,'phone':this.registerForm.phone}
              }
              this.$refs.verify.show()
            },
            success(params) {
                console.log("成功，下面是二次验证",params)
                this.sendCodedataObj.flag = 1
                Object.assign( this.sendCodedataObj, params);
                sendCaptcha(this.sendCodedataObj).then(res => {
                console.log(res)
                this.$message.success('send success'); 
                this.btnText = '120s'
                let time = 120
                let timer = setInterval(()=>{
                  time--
                  this.btnText = time+'s'
                  if(time == 0){
                    clearInterval(timer)
                    this.btnText = 'Send code'
                  }
                },1000)
              })
              // params 返回的二次验证参数, 和登录参数一起回传给登录接口，方便后台进行二次验证
            },
            onSubmit() {
                this.$refs.formRef.validate(valid => {
                    console.log(valid,'akjsjksklj')
                    if (valid) {
                        let dataObj = {}
                        if(this.activeName == 'email'){
                            dataObj = {
                                'email': this.registerForm.email,
                                'username': this.registerForm.username,
                                'password': this.registerForm.password,
                                'whatsapp': this.registerForm.whatsapp,
                                'code': this.registerForm.code,
                            }
                            }else{
                            dataObj = {
                                'phone': this.registerForm.phone,
                                'username': this.registerForm.username,
                                'password': this.registerForm.password,
                                'whatsapp': this.registerForm.whatsapp,
                                'countryCode':this.countrycode,
                                'code': this.registerForm.code,
                            }
                        }

                        if(this.$route.query.inviter){
                            dataObj.inviterShareCode = this.$route.query.inviter
                        }

                        register(dataObj).then(res => {
                            this.$emit('routerPush','login')
                            this.$message.success('register success'); 
                        })
                    } else {
       
                        return false;
                    }
                 });
    
            },
            closeDialog(){
                this.$emit('closeDialog')
            }
 
         }
     }
   </script>
   
   <style lang="scss" scoped>
   .login{
    width: 1060px;
    height: 640px;
    background: #FFFFFF;
    box-shadow: 0px 36px 56px 5px rgba(39,122,246,0.14);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    display: flex;
    .left{
        background-image: url('../../assets/common/Group.svg');
        width: 400px;
        .leftinfo{
            margin: 84px 60px auto 24px;
            img{
                width: 96p;
                height: 96px;
                opacity: 1;
            }
            .infoTitle{
                height: 48px;
                font-size: 44px;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 48px;
            }
            .infoAbstract{
                margin: 16px 0px;
                width: 316px;
                height: 84px;
                font-size: 20px;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 23px;
            }
            .infoLine{
                width: 53px;
                height: 6px;
                background: #FFFFFF;
                border-radius: 0px 0px 0px 0px;
                opacity: 1;
            }
        }

    }
    .right{
        width: 100%;
        .loginInfo{
            margin: auto 144px;
            .infoTopTitle{
                margin-top: 58px;
                :first-child{
                    height: 48px;
                    font-size: 32px;
                    font-family: PingFang SC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #000000;
                    line-height: 48px;
                }
                :last-child{
                    height: 18px;
                    font-size: 16px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #B7BDC3;
                    line-height: 18px;

                }
            }
            .loginForm{
                margin-top: 10px;
                .codeMessage{
                    display: flex;
                    .sendCodeBtn{
                        background:#1F8EF2;
                        color: #fff;
                        border-radius: 0;
                        height: 32px;
                    }
                }
                .phoneNum{
                    display: flex;
                
                }
                .otherBtn{
                    display: flex;
                    justify-content: space-between;
                    ::v-deep .el-radio__label{
                        height: 22px;
                        font-size: 14px;
                        font-family: Source Han Sans SC-Regular, Source Han Sans SC;
                        font-weight: 400;
                        color: #B7BDC3;
                        line-height: 22px;
                    }
                    .forgotBtn{
                        height: 24px;
                        font-size: 14px;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #B7BDC3;
                        line-height: 24px;
                        cursor: pointer;
                    }
                }
                .loginBtn{
                    width: 100%;
                    height: 32px;
                    color: rgba(0,0,0,0.25);
                    font-weight: 14px;
                    background: #F5F5F5;
                    border-radius: 4px 4px 4px 4px;
                    opacity: 1;
                    border: 1px solid #D9D9D9;
                }
                .createBtn{
                    text-align: center;
                    margin-top: 12px;
                    height: 24px;
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #B7BDC3;
                    line-height: 24px;
                    .create{
                        margin-left: 4px;
                        color: #1F8EF2;
                        cursor: pointer;
                    }
                }
                ::v-deep .el-form--inline .el-form-item{
                    width: 100%;
                    margin-bottom:16px;
                }
                ::v-deep .el-form-item__content{
                    width: 100%;
                }
            }
        }
    }
   }

  .closeBtn{
    position: absolute;
    right: 20px;
    top:20px;
    width: 32px;
    height: 32px;
    cursor: pointer;
   }

   ::v-deep .el-tabs__item{
    color:#333 !important;
   }
   ::v-deep .el-tabs__active-bar{
    background: #409EFF !important;
   }
   ::v-deep .el-tabs__nav-wrap::after{
    background: #fff !important;
   }
   </style>
   
<template>
  <!-- <div id="app" class="app-content"> -->
  <div style="background:#D5E3F5">
    <MyHeader style="position:fixed;top:0px;z-index:999;"></MyHeader>
    <router-view style="width:1440px;margin:0 auto;margin-top:64px;" />
    <MyFooter></MyFooter>
  </div>
  <!-- </div> -->
</template>
<script>
// import { Footer } from 'element-ui';

export default {
    mounted() {
    },
    created() {
      // localStorage.setItem("showDrawDialog",'1')
    },
    // components: { Footer }
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}
.app-content {
  margin: 0 auto;
  max-width: 1680px;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
body {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}
</style>

<template>
    <div style="width:100%;background: #1F4E98;">
        <div class="footer">
            <div class="footerLeft">
                <!-- <div class="terms">
                    <div class="footTitle">
                        TERMS &POLICIES
                    </div>
                    <div class="subTitle">
                        <div style="cursor: pointer;">
                            Privacy Policy
                        </div>
                        <div style="cursor: pointer;">
                            Terms of Service
                        </div>
                    </div>
                </div> -->
                <div class="terms">
                    <div class="newTitle">
                        Twitter
                    </div>
                    <div class="newsubTitle">
                        <div style="cursor: pointer;" @click="openNewPage(twitterInfo.description)">
                            {{twitterInfo.value}}
                        </div>
                    </div>
                    <div class="newTitle" style="margin-top:32px;">
                        Instagram
                    </div>
                    <div class="newsubTitle">
                        <div style="cursor: pointer;" @click="openNewPage(instagram.description)">
                            {{instagram.value}}
                        </div>
                    </div>
                </div>
                <!-- <div class="aboutUs">
                    <div class="footTitle">
                        About us
                    </div>
                    <div class="subTitle">
                        <div @click="gotoRouter('helpcenter')" style="cursor: pointer;">
                            Help Center
                        </div>
                    </div>
                    <div class="footTitle" style="margin-top:16px;">
                        Company Profile
                    </div>
                    <div class="subTitle">
                    </div>
                </div> -->
                <div class="aboutUs">
                    <div class="newTitle">
                        Faceboook
                    </div>
                    <div class="newsubTitle">
                        <div style="cursor: pointer;" @click="openNewPage(facebookInfo.description)">
                            {{facebookInfo.value}}
                        </div>
                    </div>
                    <div class="newTitle" style="margin-top:32px;">
                        TikTok
                    </div>
                    <div class="newsubTitle">
                        <div style="cursor: pointer;" @click="openNewPage(tiktok.description)">
                            {{tiktok.value}}
                        </div>
                    </div>
                </div>
                <!-- <div class="contentUs"> -->
                    <!-- <div>
                        <div class="footTitle">
                            Twitter
                        </div>
                        <div class="subTitle">
                            <div>
                                @ngncard
                            </div>
                        </div>

                        <div class="footTitle ngninfo">
                            Instagram
                        </div>
                        <div class="subTitle">
                            <div>
                                ngncard
                            </div>
                        </div>

                        <div class="footTitle ngninfo">
                            Faceboook
                        </div>
                        <div class="subTitle">
                            <div>
                                ngncard
                            </div>
                        </div>
                    </div> -->
                    <!-- <div style="margin-left:60px">
                        <div class="footTitle">
                            WHATS APP
                        </div>
                        <div class="subTitle">
                            <div>
                                +8615729446185
                            </div>
                        </div>

                        <div class="footTitle ngninfo">
                            WHATS APP
                        </div>
                        <div class="subTitle">
                            <div>
                                +85254808678
                            </div>
                        </div>

                        <div class="footTitle ngninfo">
                            Faceboook
                        </div>
                        <div class="subTitle">
                            <div>
                                ngncard
                            </div>
                        </div>
                    </div> -->
                <!-- </div> -->
                <div class="aboutUs">
                    <div class="newTitle">
                        Whats app
                    </div>
                    <div class="newsubTitle">
                        <div style="cursor: pointer;" @click="openNewPage(whatsappinfoList[0].description)">
                            {{ whatsappinfoList[0].value }}
                        </div>
                    </div>
                    <div class="newTitle" style="margin-top:32px;">
                        Whats app
                    </div>
                    <div class="newsubTitle">
                        <div style="cursor: pointer;" @click="openNewPage(whatsappinfoList[1].description)">
                           {{ whatsappinfoList[1].value }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="footerfight">
                <div class="topLogo">
                    <img src="../assets/common/ngnlogo.png" alt="">
                    <div style="color:#fff;">
                        Ngncard
                    </div>
                </div>
                <div class="ngnDetail">
                    <div style="color:#FCFCFC;">©2023 NGNCARD LIMITED. All Rights Reserved.</div>
                    <!-- <div style="color: #1890FF;">浙ICP备16025414号-7</div> -->
                    <!-- <div>经营许可证编号：合字B2-20190051</div> -->
                </div>
            </div>
        </div>
        <!-- <div class="companyInfo">
            Room 1002, Building 1, Fengsheng Holiday Castle, No. 498, Quanxiu Street, Xiahuai Community, Fengze Street, Fengze District, Quanzhou City, Fujian Province
        </div>        -->
    </div>
</template>

   
<script>
import {getHomeFooter} from "../../src/api/giftcard";
export default {
    name: 'footer',
    props: {
   
    },
    data() {
        return {
            twitterInfo:{},
            facebookInfo:{},
            instagram:{},
            tiktok:{},
            whatsappinfoList:[],
        }
    },
    watch: {

    },
    created(){
        this.getHomeFooterData()
    },
    methods: {
        gotoRouter(router){
            this.$router.push(router)
        },
        openNewPage(url){
            window.open(url)
        },
        getHomeFooterData(){
            this.whatsappinfoList = []
            getHomeFooter({}).then(res => {
            res.data.filter(item => {
                if(item.label == 'Twitter'){
                this.twitterInfo = item
                }
                if(item.label == 'Faceboook'){
                this.facebookInfo = item
                }
                if(item.label == 'Instagram'){
                this.instagram = item
                }
                if(item.label == 'TikTok'){
                this.tiktok = item
                }
                if(item.label == 'WHATS APP'){
                this.whatsappinfoList.push(item)
                }
            })
        })
      },
    }
}
</script>

<style lang="scss" scoped>
.companyInfo{
    padding: 0px  140px 42px 183px;
    background: #FAFAFA;
    text-align: center;
}
.footer
{
   width: 1440px;
   margin:0 auto;
   height: 410px;
   background: #1F4E98;
   display: flex;
   justify-content: space-between;
   padding: 88px  0px 12px 0px;
   .footerLeft{
    display: flex;
    justify-content: space-between;
    flex: 1;
    .contentUs{
        display: flex;
        .ngninfo{
            margin-top: 24px;
        }
    }
    .footTitle{
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0,0,0,0.85);
    }
    .subTitle{
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0,0,0,0.85);
        div{
            margin-top: 16px;
        }
    }
   }
   .footerfight{
    margin-left: 140px;
    width: 195px;
    height: 100%;
    .topLogo{
        display: flex;
        margin-bottom: 40px;
        img{
            width: 64px;
            height: 64px;
        }
        div{
            font-size: 24px;
            font-family: Source Han Sans SC-Regular, Source Han Sans SC;
            font-weight: 400;
            color: #333333;
            height: 64px;
            line-height: 64px;
        }
    }
    .ngnDetail{
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0,0,0,0.85);
        div{
            margin-bottom: 8px;
        }
    }

   }
}
.newTitle{
    font-family: Roboto, Roboto;
    font-weight: bold;
    font-size: 20px;
    color: #fff;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    text-transform: none;
}
.newsubTitle{
    font-family: Roboto, Roboto;
    font-size: 16px;
    opacity: 0.6;
    color: #FCFCFC;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    text-transform: none;
}
</style>

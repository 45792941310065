import Cookies from 'js-cookie'
import axios from "axios";
const TokenKey = 'access_token'
const RefreshTokenKey = "refresh_token"
const UserId = 'user_id'
const domain = location.href.includes(".ngncard.com") ? ".ngncard.com" : null
export function getToken() {
  return Cookies.get(TokenKey)
}
export function setToken(token) {
  return Cookies.set(TokenKey, token, {
    domain
  })
}
export function removeToken() {
  return Cookies.remove(TokenKey, {
    domain
  })
}

export function getRefreshToken() {
  return Cookies.get(RefreshTokenKey)
}
export function setRefreshToken(time) {
  return Cookies.set(RefreshTokenKey, time, {
    domain
  })
}
export function removeRefreshToken() {
  return Cookies.remove(RefreshTokenKey, {
    domain
  })
}

export function setIat(iat) {
  return Cookies.set("iat", iat, {
    domain
  })
}

export function setExp(exp) {
  return Cookies.set("exp", exp, {
    domain
  })
}

export function getUserId() {
  return Cookies.get(UserId)
}
export function setUserId(id) {
  return Cookies.set(UserId, id)
}
export function removeUserId() {
  return Cookies.remove(UserId)
}

export function loginOut() {
  removeRefreshToken();
  removeToken();
  removeUserId();
}
